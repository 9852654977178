import { PlayerViewLocal } from "@/types/PlayerView"
import {
    ComponentProps,
    CSSProperties,
    useEffect,
    useRef,
    useState,
} from "react"
import {
    EmptyVideoData,
    Video,
    VideoData,
    VideoRefreshDelta,
    VideoSubscriber,
} from "@/services/VideoService"
import { debounce, times } from "lodash"
import LoggerService, { LogLevel } from "@/services/LoggerService"

const PerformanceSampleDelta = 1000

export interface VideoDisplayProps extends ComponentProps<"div"> {
    identifier: string
}

export default function VideoDisplay({
    identifier,
    children,
    ...props
}: VideoDisplayProps) {
    const [imgData, setImgData] = useState<VideoData>(EmptyVideoData)
    const [timestamp, setTimestamp] = useState(0)
    const [refreshDelta, setRefreshDelta] = useState(VideoRefreshDelta)
    const [lateFrameCount, setLateFrameCount] = useState(0)
    const [opacity, setOpacity] = useState(1)

    const isMounted = useRef(true)
    const sampleAt = useRef(0)

    const logger = new LoggerService(
        `VideoDisplay$${identifier}`,
        LogLevel.NonTrivial
    )

    useEffect(() => {
        const subscriber: VideoSubscriber = {
            updated: (updateTimestamp: number) => {
                if (isMounted.current) setTimestamp(updateTimestamp)
            },
        }
        Video.subscribe(identifier, subscriber)

        return () => {
            isMounted.current = false
            Video.unsubscribe(identifier)
        }
    }, [])

    const updateImgData = debounce((newImgData: VideoData) => {
        if (!newImgData.empty && newImgData.timestamp > sampleAt.current) {
            sampleAt.current = newImgData.timestamp + PerformanceSampleDelta
            const delta = Video.lastTimestamp - newImgData.timestamp
            if (delta > refreshDelta) {
                const latest = Video.get(identifier).timestamp
                setLateFrameCount(lateFrameCount + 1)
                if (opacity > 0.15) setOpacity(opacity - 0.15)
                if (latest > newImgData.timestamp) {
                    logger.warn(
                        `Video frame is outdated - diff is ${latest - newImgData.timestamp}ms (delta ${delta}ms)`
                    )
                    return
                }
            } else {
                if (lateFrameCount > 0) {
                    setLateFrameCount(lateFrameCount - 1)
                    if (opacity < 1) setOpacity(opacity + 0.15)
                }
            }
        }

        if (lateFrameCount == 0 || newImgData.empty) {
            setImgData((prevState: VideoData) => {
                return newImgData
            })
        }
    }, 10)

    useEffect(() => {
        if (isMounted.current) {
            const videoData =
                timestamp == 0
                    ? EmptyVideoData
                    : Video.get(identifier, timestamp)
            updateImgData(videoData)
        }
    }, [timestamp])

    const style: CSSProperties = {
        ...props.style,
        backgroundImage: `url(${imgData.data}`,
    }
    if (lateFrameCount) {
        style.filter = `grayscale(100%)`
        style.opacity = opacity
    }

    return (
        <div {...props} style={style}>
            {children}
        </div>
    )
}
