export default function Plus(props: React.ComponentProps<"svg">) {
    return (
        <svg
            width="17"
            height="16"
            viewBox="0 0 17 16"
            fill="currentColor"
            stroke="currentColor"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M1.91406 1.41406L15.0858 14.5858"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M15.0858 1.41406L1.91406 14.5858"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    )
}
