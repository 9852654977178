import { Agent } from "@/services/BonzaService"
import LoggerService from "@/services/LoggerService"
import {
    SetRemotePanMessage,
    SetRemoteAzimuthMessage,
} from "./AppMessage"
import { PlayerViewRemote } from "./PlayerView"
import { SliderMinMax, KnobMinMax } from "@/components/AudioControlLocal"
import {
    RISSFlags,
    RemoteInfo,
    VPSaveRemote,
    workingDataStore,
} from "@/types/XRemoteManager"

export function isIP(ip: string) {
    // if (typeof (ip) !== 'string') {
    //     return false;
    // }
    if (ip.length <= 0) {
        return false
    }
    if (!ip.match(/\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}/)) {
        return false
    }
    return (
        ip
            .split(".")
            .filter(
                (octect) => parseInt(octect) >= 0 && parseInt(octect) <= 255
            ).length === 4
    )
}

export class RemoteManager {
    public logger: LoggerService = new LoggerService("RemoteManager") // 240326 make public so can log from reducer
    private _initialisationState: RISSFlags = RISSFlags.RISS_Uninitialised
    public workingData: workingDataStore
    private VUCount: number = 0

    constructor() {
        this.workingData = new workingDataStore()
    }

    // REMOTE VP saves (totally sep from either the remotes array or players for now)
    private _RemoteIDVPSaves = new Array<VPSaveRemote>()
    // caveat: assumes vol uses a Slider and pan uses a Knob
    // NOTE: KB 2406528 If/when the control is displayed initially it MAY still only have IP as remote.remoteName & port ''
    // that works since I moved setting the vals to outside the isValidIP etc checks...
    // (ie in the below checks - it still finds an entry if name matches & there is no port.)
    // the problem is when it gets verified & the IP and port change to the real ones...
    // I attempt to transfer in verifyRemote but I think there's still an edge case that doesnt work...
    public getRemoteVolumeForID(ID: string) {
        const index = this._RemoteIDVPSaves.findIndex(
            (p) => p.ID === ID
        )
        if (index >= 0) {
            return this._RemoteIDVPSaves[index].vol
        } else {
            return SliderMinMax.default
        }
    }

    public setRemoteVolumeForID(ID: string, value: number) {
        const index = this._RemoteIDVPSaves.findIndex(
            (p) => p.ID === ID
        )
        if (index >= 0) {
            this._RemoteIDVPSaves[index].vol = value
        } else {
            const VP: VPSaveRemote = {
                ID: ID,
                vol: value,
                pan: KnobMinMax.default,
            }
            this._RemoteIDVPSaves.push(VP)
        }
    }

    public getRemotePanForID(ID: string) {
        const index = this._RemoteIDVPSaves.findIndex(
            (p) => p.ID === ID
        )
        if (index >= 0) {
            return this._RemoteIDVPSaves[index].pan
        } else {
            return KnobMinMax.default
        }
    }

    public setRemotePanForID(ID: string, value: number) {
        const index = this._RemoteIDVPSaves.findIndex(
            (p) => p.ID === ID
        )
        if (index >= 0) {
            this._RemoteIDVPSaves[index].pan = value
        } else {
            const VP: VPSaveRemote = {
                ID: ID,
                vol: SliderMinMax.default,
                pan: value,
            }
            this._RemoteIDVPSaves.push(VP)
        }
    }

    findRemoteFromName(name: string): RemoteInfo | null {
        // !!! todo make lambda, ensure index set at point of adding...
        const nRemotes = this.workingData.remotes.length
        for (let i = 0; i < nRemotes; i++) {
            var remote: RemoteInfo
            if (this.workingData.remotes[i].remoteName == name) {
                remote = this.workingData.remotes[i]
                return remote
            }
        }
        return null
    }

    public get initState() {
        return this._initialisationState
    }

    public set initState(newState: RISSFlags) {
        this._initialisationState = newState
    }

    public handlePanChange(panValue: number, player: PlayerViewRemote | null) {
        if (player) {
            Agent.send(
                new SetRemotePanMessage(
                    `${player.id}`,
                    panValue.toString()
                )
            )
        }
    }

    public handleAzimuthChange(
        azimuth: number,
        distance: number,
        player: PlayerViewRemote | null
    ) {
        if (player) {
            Agent.send(
                new SetRemoteAzimuthMessage(
                    `${player.id}`,
                    azimuth,
                    distance
                )
            )
        }
    }
}
