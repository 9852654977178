// This is a functional service containing getter functions for axios
import axios, { AxiosResponse } from "axios"
import LoggerService from "@/services/LoggerService"
import {
    GroupResponse,
    GroupRequest,
    GroupType,
    GroupInviteRequest,
} from "@/types/Group"
import { UserModel } from "@/types/UserClass"
import { getRequest, postRequest, putRequest } from "./helpers/AxiosHelper"
import { GroupInviteResponse } from "@/types/Invite"
import { BonzaSessionResponse } from "@/types/BonzaSession"

export const getGroups = async () => {
    const debRouteName = "api.group.index"
    const debRoute = route(debRouteName)

    const res = await getRequest<GroupResponse[]>(debRoute, "Groups")

    console.log(res)

    return res
        .sort((a, b) => b.name.localeCompare(a.name))
        .reduce((acc, element) => {
            if (!element.avatar?.includes("default")) return [element, ...acc]
            else return [...acc, element]
        }, new Array<GroupResponse>())
}

export const getGroup = async (group: string) => {
    const debRouteName = "api.group.get"
    const debRoute = route(debRouteName, { group })

    const res = await getRequest<GroupResponse>(debRoute, "Groups")

    return res
}

export const getGroupTypes = async () => {
    const debRouteName = "api.group.types"
    const debRoute = route(debRouteName)

    const res = await getRequest<GroupType[]>(debRoute, "Groups")

    return res
}

export const postGroup = async (data: GroupRequest) => {
    const debRouteName = "api.group.create"
    const debRoute = route(debRouteName, { addCreator: true })

    const res = await postRequest<GroupRequest, GroupResponse>(
        debRoute,
        data,
        "Groups"
    )

    return res
}

export const putGroup = async (id: string, data: GroupRequest) => {
    const debRouteName = "api.group.update"
    const debRoute = route(debRouteName, { group: id })

    const res = await putRequest<GroupRequest, GroupResponse>(
        debRoute,
        data,
        "Groups"
    )

    return res
}

export const getGroupMembers = async (id: string) => {
    const logger = new LoggerService("Groups")
    const debRouteName = "api.group.members"
    const debRoute = route(debRouteName, { id })

    const res = await axios
        .get(debRoute)
        .then((response: AxiosResponse<UserModel[]>) => {
            console.log(response.data)
            return response.data
        })
        .catch((error) => {
            logger.warn(`${debRouteName} call failed: ${error}`)
            return undefined
        })

    if (res === undefined) throw Error()

    return res
}

export const getGroupSessions = async (id: string) => {
    const debRouteName = "api.group.sessions"
    const debRoute = route(debRouteName, { id })

    const res = await getRequest<BonzaSessionResponse[]>(debRoute, "Groups")

    return res
}

export const postInviteToGroup = async (
    groupId: string,
    userId: string,
    data: GroupInviteRequest
) => {
    const debRouteName = "api.group.invite"
    const debRoute = route(debRouteName, { group: groupId, user: userId })

    const res = await postRequest<GroupInviteRequest, GroupInviteResponse>(
        debRoute,
        data,
        "Groups"
    )

    return res
}

export const postGroupAcceptInvite = async (id: number) => {
    const debRouteName = "api.group.accept"
    const debRoute = route(debRouteName, { id })

    const res = await postRequest<never, GroupResponse>(
        debRoute,
        undefined,
        "Groups"
    )

    return res
}

export const postGroupRevokeInvite = async (id: number) => {
    const debRouteName = "api.group.revoke"
    const debRoute = route(debRouteName, { id })

    const res = await postRequest<never, GroupInviteResponse>(
        debRoute,
        undefined,
        "Groups"
    )

    return res
}
