import { ComponentProps, useEffect, useRef, useState } from "react"
import { Agent } from "@/services/BonzaService"
import { PlayerViewLocal } from "@/types/PlayerView"
import { AudioPanelMaster } from "./AudioPanelMaster"
import {
    EmptyVideoImage,
    Video,
    VideoSubscriber,
} from "@/services/VideoService"
import VideoDisplay from "@/components/VideoDisplay"
import NetworkQualityIndicator from "./parts/NetworkQualityIndicator"
import {useBonzaContext} from "@/context/BonzaContext";

export interface MasterMixerProps
    extends PlayerViewLocal,
        ComponentProps<"div"> {
    showLocalChannels?: boolean
    setShowLocalChannels?: (show: boolean) => void
}
export const MASTERCHANNELNUMBER: number = -1

export const MasterChannel = {
    identifier: "Master",
    agent: Agent,
    channel: MASTERCHANNELNUMBER,
}

export default function MasterMixer(props: MasterMixerProps) {
    const identifier = props.identifier

    const { showLocalChannels, setShowLocalChannels, ...domProps } = props

    const [showingLocalChannels, setShowingLocalChannels] =
        useState(showLocalChannels)

    const toggleLocalChannels = () => {
        if (setShowLocalChannels) setShowLocalChannels(!showingLocalChannels)
        setShowingLocalChannels(!showingLocalChannels)
    }

    const todisplay: boolean = import.meta.env.VITE_APP_ENV != "production"

    const { getConnection } = useBonzaContext()

    return (
        <div
            {...domProps}
            className={`
                ${props.className}

                flex transform flex-col bg-bonza-dark-semi transition
            `}
        >
            <VideoDisplay
                className={`
                    absolute left-[-23px] right-[-23px] top-[-31px] mb-[32px]
                    h-[132px] bg-cover bg-center text-center
                `}
                identifier={"__SELF"}
            >
                <p className="pt-[110px] text-bonza-primary">{identifier} (you)</p>
                {todisplay ? (
                    <NetworkQualityIndicator
                        id={getConnection()!.id}
                        style={{
                            position: "absolute",
                            top: "120px",
                            right: "5px",
                        }}
                    />
                ) : (
                    <></>
                )}
            </VideoDisplay>
            <div className="mt-[120px]">
                {setShowLocalChannels ? (
                    <button
                        className={`
                            flex rounded-[25px] py-4 text-bonza-pale

                            ${
                                showingLocalChannels
                                    ? `bg-bonza-primary`
                                    : `bg-bonza-dark`
                            }

                            w-full items-center justify-center
                        `}
                        onClick={toggleLocalChannels}
                    >
                        {showingLocalChannels ? "Close" : "Open"} local channels
                    </button>
                ) : null}
            </div>
            <div className={"mt-[22px] flex bg-bonza-dark-semi"}>
                <AudioPanelMaster
                    agent={Agent}
                    channel={MASTERCHANNELNUMBER}
                    className="flex-1 items-center px-5"
                />
            </div>
        </div>
    )
}
