import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/react"
import { Link } from "@inertiajs/react"
import { Method } from "@inertiajs/core"

type HeaderMenuProps = {
    icon: JSX.Element
    options: {
        name: string
        href: string
        method?: Method
        as?: string
        onClick?: () => void
    }[]
}

export default function HeaderMenu({ icon, options }: HeaderMenuProps) {
    return (
        <Menu>
            <MenuButton className="group">
                <div
                    className={`
                        group flex items-center justify-center rounded-full
                        bg-gradient-to-t from-bonza-primary via-bonza-primary
                        to-bonza-pale-t-70 p-0.5 text-bonza-pale-t-70 ring-0
                        transition duration-100 ease-in-out

                        group-hover:from-bonza-dark-semi
                        group-hover:via-bonza-dark-semi
                        group-hover:to-bonza-dark-semi group-hover:ring
                        group-hover:ring-bonza-primary
                    `}
                >
                    <div
                        className={`
                            rounded-full bg-bonza-dark p-3 transition
                            duration-100 ease-in-out

                            group-hover:bg-bonza-dark-semi
                        `}
                    >
                        {icon}
                    </div>
                </div>
            </MenuButton>
            <MenuItems
                anchor="bottom end"
                transition
                className={`
                    mt-1 flex w-52 origin-top-right flex-col rounded-xl border
                    border-bonza-dark bg-bonza-dark-semi p-1 text-sm/6
                    text-bonza-pale transition duration-200 ease-in-out

                    data-[closed]:-translate-y-1 data-[closed]:opacity-0

                    focus:outline-none
                `}
            >
                {options.map(({ name, ...opt }) => (
                    <MenuItem key={`menu-${name}`}>
                        <Link
                            className={`
                                flex w-full items-center rounded-lg px-3 py-1.5

                                data-[focus]:bg-bonza-dark
                            `}
                            {...opt}
                        >
                            {name}
                        </Link>
                    </MenuItem>
                ))}
            </MenuItems>
        </Menu>
    )
}
