import { IAgentService } from "@/services/AgentService"
import { WebSocketService } from "./WebSocket"
import { RemoteConnectState } from "./XRemoteManager"

export interface PlayerViewGeneric {
    identifier: string
    img?: string
    agent: WebSocketService & IAgentService
}

export type RTInfo = {
    latency?: string
    dropout?: string
    jitter?: string
}

export const RTInfoEmptyField = "."

export interface PlayerViewRemote extends PlayerViewGeneric {
    // remote
    id: number
    ip?: string
    port?: string
    readiness?: RemoteConnectState
    info?: RTInfo
    pan: number
    x?: number
    y?: number
}

export interface PlayerViewLocal extends PlayerViewGeneric {
    // remote
    channel: number
}

// export interface PlayerView extends PlayerViewRemote {
//     // at pres exactly same..
// }
