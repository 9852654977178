import { SliderThumb as SliderThumbBase } from "@mui/material"
import { PropsWithChildren } from "react"

export default function SliderThumbHorizontal({
    children,
    ...props
}: PropsWithChildren<{ horizontal?: boolean }>) {
    return props.horizontal ? (
        ""
    ) : (
        <SliderThumbBase {...props}>
            {children}
            <div
                style={{ width: "100%", marginLeft: "-50px", marginTop: "8px" }}
            >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="74.393"
                    height="44.741"
                    viewBox="0 0 74.393 44.741"
                >
                    <defs>
                        <linearGradient
                            id="linear-gradient"
                            x1="0.5"
                            x2="1"
                            y2="0.51"
                            gradientUnits="objectBoundingBox"
                        >
                            <stop offset="0" stopColor="#202126" />
                            <stop offset="0" stopColor="#202126" />
                            <stop offset="1" stopColor="#202126" />
                        </linearGradient>
                        <filter
                            id="Rectangle_2058"
                            x="0"
                            y="0"
                            width="74.393"
                            height="44.741"
                            filterUnits="userSpaceOnUse"
                        >
                            <feOffset dy="3" />
                            <feGaussianBlur stdDeviation="3" result="blur" />
                            <feFlood floodOpacity="0.161" />
                            <feComposite operator="in" in2="blur" />
                            <feComposite in="SourceGraphic" />
                        </filter>
                        <linearGradient
                            id="linear-gradient-2"
                            y1="0.5"
                            x2="1"
                            y2="0.5"
                            gradientUnits="objectBoundingBox"
                        >
                            <stop offset="0" stopColor="#202126" />
                            <stop offset="0" stopColor="#202126" />
                            <stop offset="1" stopColor="#202126" />
                        </linearGradient>
                    </defs>
                    <g
                        transform="matrix(1, 0, 0, 1, 0, 0)"
                        filter="url(#Rectangle_2058)"
                    >
                        <rect
                            id="Rectangle_2058-2"
                            width="26.741"
                            height="56.393"
                            rx="13"
                            transform="translate(9 32.74) rotate(-90)"
                            fill="url(#linear-gradient)"
                        />
                    </g>
                    <rect
                        id="Rectangle_2059"
                        width="23.332"
                        height="53.122"
                        rx="11.666"
                        transform="translate(10.636 31.036) rotate(-90)"
                        fill="url(#linear-gradient-2)"
                    />
                    <line
                        id="Line_193"
                        x2="17.447"
                        transform="translate(37.196 10.466) rotate(90)"
                        fill="none"
                        stroke="#525AE9"
                        strokeLinecap="round"
                        strokeWidth="1"
                    />
                    <line
                        id="Line_195"
                        x2="17.447"
                        transform="translate(34.196 10.466) rotate(90)"
                        fill="none"
                        stroke="#525AE9"
                        strokeLinecap="round"
                        strokeWidth="1"
                    />
                    <line
                        id="Line_194"
                        x2="17.447"
                        transform="translate(40.196 10.466) rotate(90)"
                        fill="none"
                        stroke="#525AE9"
                        strokeLinecap="round"
                        strokeWidth="1"
                    />
                </svg>
            </div>
        </SliderThumbBase>
    )
}
