import { MenuHeading, MenuItem, MenuSection } from "@headlessui/react"
import PrimaryButton, { SecondaryButton } from "./Button"
import { UserModel } from "@/types/UserClass"

type InviteProps = {
    message: string
    invitedBy: UserModel
    name: string
    id: number
    time?: string
    callback: (id: number, accept: boolean) => void
}

export default function Invite({ message, invitedBy, name, id, time, callback }: InviteProps) {
    return (
        <MenuSection
            key={`invite-${id}`}
            className={`
                flex min-w-96 flex-col items-stretch gap-3 rounded-xl
                bg-bonza-pale-t-7 p-5
            `}
        >
            <div className="flex flex-row items-center justify-start gap-4">
                <div
                    className={`
                        group relative mx-0 inline-flex h-12 w-12 items-center
                        justify-center overflow-hidden rounded-full border-[2px]
                        border-bonza-dark-semi
                    `}
                >
                    <img
                        className="h-full w-full rounded-full object-cover"
                        data-tooltip-target="tooltip"
                        src={`/images/avatars/user/${invitedBy.avatar}`}
                    />
                </div>
                <div className="flex flex-col items-start">
                    <MenuHeading className={`text-lg`}>
                        {`Invite to ${name ?? `Group ${id}`}`}
                    </MenuHeading>
                    <p className="text-sm text-bonza-pale-t-75">{`From ${invitedBy.name}`}</p>
                </div>
            </div>
            <p className="text-sm text-bonza-pale">{message}</p>
            <div className="flex flex-row items-end justify-between gap-4">
                <p className="text-sm text-bonza-pale-t-75">{time}</p>
                <div className="mt-2 flex flex-row justify-end gap-3">
                    <MenuItem>
                        <PrimaryButton onClick={() => callback(id, true)}>
                            {"Accept"}
                        </PrimaryButton>
                    </MenuItem>
                    <MenuItem>
                        <SecondaryButton onClick={() => callback(id, false)}>
                            {"Reject"}
                        </SecondaryButton>
                    </MenuItem>
                </div>
            </div>
        </MenuSection>
    )
}
