import {
    Dialog,
    DialogContent,
    DialogProps,
    DialogTitle,
    IconButton,
    Paper,
    PaperProps,
    SelectChangeEvent,
} from "@mui/material"
import PrimaryButton from "@/components/Button"
import { Agent, LocalDevice, LocalRemoteManager } from "@/services/BonzaService"
import { useEffect, useRef, useState } from "react"
import { SoundCardIOType } from "@/types/DeviceSelector"
import VideoDeviceSelector from "@/components/selectors/VideoDeviceSelector"
import {
    CancelTwoTone as CloseIcon,
    Construction as SettingsIcon,
    ExitToApp as InputIcon,
    Output as OutputIcon,
    Videocam as VideoIcon,
    Engineering as AdvancedIcon,
} from "@mui/icons-material"
import LabelledIconButton from "@/components/LabelledIconButton"
import { LR_Type } from "@/components/AudioControlLocal"
import VuMeterMini from "@/components/VuMeterMini"
import { ReadyState } from "@/types/WebSocket"
import Draggable from "react-draggable"
import {
    AudioInitialisationSequenceStates,
    fNOP,
    IDevice,
} from "@/types/Device"
import InputSoundCardSelector from "@/components/selectors/InputSoundCardSelector"
import OutputSoundCardSelector from "@/components/selectors/OutputSoundCardSelector"
import InputChannelsSelector from "@/components/selectors/InputChannelsSelector"
import OutputChannelsSelector from "@/components/selectors/OutputChannelsSelector"
import LatencySelector from "@/components/selectors/LatencySelector"
import {
    CodecOptionStrings,
    inputChannelCountPossibilities,
    ManualJitterValueStrings,
    NetworkBufferSizeStrings,
    openASIOPanelMessage,
    playTestSoundMessage,
    playTestSoundMode,
    SampleRateValueStrings,
    setReceiverBufferSizeMessage,
    setStreamQualityMessage,
} from "@/types/AppMessage"

import DiffuseIRDepthSelector from "@/components/selectors/DiffuseIRDepthSelector"
import TwoChannelZoneSelector from "@/components/selectors/TwoChannelZoneSelector"
import DirectAudioCheckbox from "@/components/checkboxes/DirectAudioCheckbox"
import VideoColorSelector from "./selectors/VideoColourSelector"
import VideoResolutionSelector from "./selectors/VideoResolutionSelector"
import VideoDisplay from "@/components/VideoDisplay"
import GenericSelector from "@/components/selectors/GenericSelector"
import { useBonzaContext } from "@/context/BonzaContext"
import Checkbox from "./Checkbox"

export interface LocalDeviceDialogProps extends DialogProps {
    handleClose: () => void
}

function DraggablePaper(props: PaperProps) {
    return (
        <Draggable
            handle="#local-device-dialog-title"
            cancel="[class*='MuiDialogContent-root']"
        >
            <Paper
                {...props}
                sx={{ borderRadius: "10px", height: "540px" }}
                style={{ maxWidth: "1000px" }}
            />
        </Draggable>
    )
}

function areAudioSettingsReady(): boolean {
    return LocalDevice.initState >= AudioInitialisationSequenceStates.AISS_3
}

export default function LocalDeviceDialog(props: LocalDeviceDialogProps) {
    const { screenSharing, toggleScreenSharing, setInputChannelCount, locals } =
        useBonzaContext()

    const [mode, setMode] = useState("input")

    const [allowSelect, setAllowSelect] = useState(
        Agent.readyState == ReadyState.Open
    )
    const [inputDevice, setInputDevice] = useState(
        LocalDevice.activeInputSoundCard
    )
    const [outputDevice, setOutputDevice] = useState(
        LocalDevice.activeOutputSoundCard
    )
    const [videoDevice, setVideoDevice] = useState(
        LocalDevice.activeVideoDevice
    )
    const [remoteConnectName0, setRemoteConnectName0] = useState("")

    const labelStyle = {
        cursor: "pointer",
    }

    const { ...domProps } = props
    const isMounted = useRef(true)

    function asioClickHandler() {
        const os: string | null = LocalRemoteManager.workingData.selfInfo.OS
        if (os == null || os == "MAC OSX") {
            return // Mac doesnt use asio
        }
        if (
            LocalDevice.activeInputSoundCard &&
            LocalDevice.activeOutputSoundCard
        ) {
            const indexin: number = LocalDevice.activeInputSoundCard.index
            const indexout: number = LocalDevice.activeOutputSoundCard.index
            Agent.send(new openASIOPanelMessage(indexin, indexout))
        }
    }

    const handleSelectChange = (
        event:
            | React.ChangeEvent<HTMLInputElement>
            | SelectChangeEvent
            | {
                  target: {
                      name: string
                      value: number | string | readonly string[]
                  }
              }
    ) => {
        switch (event.target.name) {
            case "InputChannelsSelector":
                if (locals.length) {
                    //const count = inputChannelCountPossibilities[LocalDevice.deviceDataStore.inChansSelectIndex];
                    const count =
                        inputChannelCountPossibilities[
                            Number(
                                LocalDevice.getSavedSettings().inChannelsIndex
                            )
                        ]
                    setInputChannelCount(count)
                }
                break
            case "OutputChannelsSelector":
                break
            case "VideoResolutionSelector":
            case "VideoColourSelector": {
                const settings = LocalDevice.getSavedSettings()
                if (event.target.name == "VideoResolutionSelector") {
                    settings.videoResolutionIndex = `${event.target.value}`
                } else {
                    settings.videoColorIndex = `${event.target.value}`
                }
                LocalDevice.saveSavedSettings(settings)
                break
            }
        }
    }

    useEffect(() => {
        isMounted.current = true

        const deviceChangeListener = {
            handleDeviceChange: (device: IDevice) => {
                setInputDevice(device.activeInputSoundCard)
                setOutputDevice(device.activeOutputSoundCard)
                setVideoDevice(device.activeVideoDevice)
            },
        }

        const agentListener = {
            handleSocketEvent: () => {
                setAllowSelect(Agent.readyState == ReadyState.Open)
            },
        }

        LocalDevice.addDeviceChangeListener(deviceChangeListener)
        Agent.addEventListener(agentListener)
        return () => {
            isMounted.current = false
            LocalDevice.removeDeviceChangeListener(deviceChangeListener)
            Agent.removeEventListener(agentListener)
        }
    }, [])

    return (
        <Dialog
            open={props.open}
            PaperComponent={DraggablePaper}
            aria-labelledby={"local-device-dialog-title"}
            onClose={() => {
                props.handleClose()
            }}
        >
            <div
                className={`
                    absolute bottom-0 left-0 top-0 flex w-[215px] flex-col
                    bg-bonza-dark p-4
                `}
            >
                <LabelledIconButton
                    label="Input"
                    flex="row"
                    height="60px"
                    width="auto"
                    labelstyle={labelStyle}
                    active={`${mode == "input"}`}
                    onClick={() => setMode("input")}
                >
                    <InputIcon className="rotate-180" />
                </LabelledIconButton>
                <LabelledIconButton
                    label="Output"
                    flex="row"
                    height="60px"
                    width="auto"
                    labelstyle={labelStyle}
                    active={`${mode == "output"}`}
                    onClick={() => setMode("output")}
                >
                    <OutputIcon />
                </LabelledIconButton>
                <LabelledIconButton
                    label="Video"
                    flex="row"
                    height="60px"
                    width="auto"
                    labelstyle={labelStyle}
                    active={`${mode == "video"}`}
                    onClick={() => setMode("video")}
                >
                    <VideoIcon className="rotate-180" />
                </LabelledIconButton>
                <LabelledIconButton
                    label="Settings"
                    flex="row"
                    height="60px"
                    width="auto"
                    labelstyle={labelStyle}
                    active={`${mode == "settings"}`}
                    onClick={() => setMode("settings")}
                >
                    <SettingsIcon />
                </LabelledIconButton>
                {import.meta.env.VITE_APP_ENV != "production" && (
                    <LabelledIconButton
                        label="Advanced"
                        flex="row"
                        height="60px"
                        width="auto"
                        labelstyle={labelStyle}
                        active={`${mode == "advanced"}`}
                        onClick={() => setMode("advanced")}
                    >
                        <AdvancedIcon />
                    </LabelledIconButton>
                )}
            </div>

            <DialogTitle
                style={{
                    cursor: "move",
                    textTransform: "capitalize",
                    paddingLeft: "240px",
                }}
                className="bg-bonza-dark-semi text-white"
                id="local-device-dialog-title"
            >
                {mode}
                <IconButton
                    style={{ position: "absolute", top: "8px", right: "8px" }}
                    onClick={() => props.handleClose()}
                >
                    <CloseIcon
                        className={`
                            text-bonza-pale opacity-85

                            hover:opacity-100
                        `}
                    />
                </IconButton>
            </DialogTitle>
            <DialogContent
                className={`
                    border-bonza-dark-semi bg-bonza-dark-semi text-bonza-pale
                `}
                sx={{
                    width: "500px",
                    marginLeft: "215px",
                }}
            >
                {mode == "input"
                    ? inputMode()
                    : mode == "output"
                      ? outputMode()
                      : mode == "settings"
                        ? settingsMode()
                        : mode == "advanced"
                          ? advancedMode()
                          : /* else video mode */
                            videoMode()}
            </DialogContent>
        </Dialog>
    )

    function inputMode() {
        return (
            <>
                <div
                    className={`
                        grid w-full grid-cols-[30%_70%] gap-2 gap-y-4 pt-4
                    `}
                >
                    <label
                        className={`
                            pt-1 text-sm

                            ${!allowSelect ? `text-bonza-grey` : ""}
                        `}
                    >
                        Input device
                    </label>
                    <InputSoundCardSelector
                        disabled={!allowSelect}
                        title="Input Card"
                        device={LocalDevice}
                        type={SoundCardIOType.Input}
                    />

                    <label
                        className={`
                            pt-1 text-sm

                            ${inputDevice == null ? `text-bonza-grey` : ""}
                        `}
                    >
                        Input channels
                    </label>
                    <InputChannelsSelector
                        disabled={inputDevice == null}
                        agent={Agent}
                        device={LocalDevice}
                        handleChange={handleSelectChange}
                        name="InputChannelsSelector"
                    />

                    <label
                        className={`
                            pt-1 text-sm

                            ${inputDevice == null ? `text-bonza-grey` : ""}
                        `}
                    >
                        Latency
                    </label>
                    <LatencySelector
                        disabled={inputDevice == null}
                        agent={Agent}
                        device={LocalDevice}
                    />
                </div>
                <hr className="my-8 border-bonza-grey" />
                <label
                    className={`
                        pt-1 text-sm

                        ${inputDevice == null ? `text-bonza-grey` : ""}
                    `}
                >
                    Test your input
                </label>
                <VuMeterMini
                    inactive={false}
                    invert={false}
                    lrtype={LR_Type.Local}
                    columns={2}
                    agent={Agent}
                    channel={0}
                    className={`-ml-[2px] h-[30px] w-[435px]`}
                />
            </>
        )
    }

    function outputMode() {
        return (
            <>
                <div
                    className={`
                        grid w-full grid-cols-[30%_70%] gap-2 gap-y-4 pt-4
                    `}
                >
                    <label
                        className={`
                            pt-1 text-sm

                            ${!allowSelect ? `text-bonza-grey` : ""}
                        `}
                    >
                        Output device
                    </label>

                    <OutputSoundCardSelector
                        disabled={!allowSelect}
                        title="Output Card"
                        device={LocalDevice}
                        type={SoundCardIOType.Output}
                    />

                    <label
                        className={`
                            pt-1 text-sm

                            ${outputDevice == null ? `text-bonza-grey` : ""}
                        `}
                    >
                        Output channels
                    </label>
                    <OutputChannelsSelector
                        disabled={outputDevice == null}
                        agent={Agent}
                        device={LocalDevice}
                        handleChange={handleSelectChange}
                        name="OutputChannelsSelector"
                    />
                </div>
                <hr className="my-8 border-bonza-grey" />
                <label
                    className={`
                        pt-1 text-sm

                        ${outputDevice == null ? `text-bonza-grey` : ""}
                    `}
                >
                    Test your output
                </label>
                <VuMeterMini
                    inactive={true}
                    invert={false}
                    lrtype={LR_Type.Local}
                    columns={2}
                    agent={Agent}
                    className={`-ml-[2px] mb-3 h-[30px] w-[435px]`}
                />
                <PrimaryButton
                    disabled={outputDevice == null}
                    onClick={() =>
                        Agent.send(
                            new playTestSoundMessage(
                                1.5,
                                playTestSoundMode.left_then_right
                            )
                        )
                    }
                >
                    Play sound
                </PrimaryButton>
            </>
        )
    }

    function videoMode() {
        return (
            <>
                <div
                    className={`
                        grid w-full grid-cols-[30%_70%] gap-2 gap-y-4 pt-4
                    `}
                >
                    <label
                        className={`
                            pt-1 text-sm

                            ${!allowSelect ? `text-bonza-grey` : ""}
                        `}
                    >
                        Camera
                    </label>
                    <VideoDeviceSelector
                        disabled={!allowSelect}
                        device={LocalDevice}
                        handleChange={handleSelectChange}
                    ></VideoDeviceSelector>
                    {/* Rez */}
                    <label className={`pt-1 text-sm`}>Resolution</label>
                    <VideoResolutionSelector
                        handleChange={handleSelectChange}
                    />
                    {/* BW */}
                    <label className={`pt-1 text-sm`}>Colour Mode</label>
                    <VideoColorSelector handleChange={handleSelectChange} />
                    {/* Screen sharing */}
                    <label className={`pt-1 text-sm`}>Screen Sharing</label>
                    <Checkbox
                        checked={screenSharing}
                        onChange={toggleScreenSharing}
                    />
                </div>
                <hr className="my-8 border-bonza-grey" />
                <VideoDisplay
                    {...domProps}
                    identifier={"__SETTINGS"}
                    className={`
                        ${props.className}

                        ml-2 h-[240px] w-[435px] overflow-hidden rounded-3xl
                        border border-bonza-dark bg-bonza-dark bg-cover
                        bg-center
                    `}
                    style={videoDevice ? {} : { opacity: 0.5 }}
                ></VideoDisplay>
            </>
        )
    }

    function settingsMode() {
        return (
            <>
                <div
                    className={`
                        grid w-full grid-cols-[30%_70%] gap-2 gap-y-4 pt-4
                    `}
                >
                    <label className={`pt-1 text-sm`}>Diffuse IR depth</label>
                    <DiffuseIRDepthSelector />
                    <label className={`pt-1 text-sm`}>Two-channel zone</label>
                    <TwoChannelZoneSelector />
                    <label className={`pt-1 text-sm`}>Direct on/off</label>
                    <DirectAudioCheckbox />
                </div>
                <hr className="my-8 border-bonza-grey" />

                {navigator.userAgent.toLowerCase().indexOf("windows") > -1 ? (
                    <>
                        <hr className="my-8 border-bonza-grey" />
                        <PrimaryButton
                            disabled={false}
                            onClick={asioClickHandler}
                        >
                            ASIO
                        </PrimaryButton>
                    </>
                ) : null}
            </>
        )
    }

    function advancedMode() {
        {
            /* for now only; use simple GenericSelectors - TBD whether need to merge with (say) something like SelectMenu
         or devise some other better way - eventually we ought to standardise all these GUI items & the way they link

         to some sort of default/save mechanism ... WIP */
        }
        return (
            <>
                <div
                    className={`
                        grid w-full grid-cols-[30%_70%] gap-2 gap-y-4 pt-4
                    `}
                >
                    <label className={`pt-1 text-sm`}>Device Sample Rate</label>
                    <GenericSelector
                        title="Device Sample Rate"
                        options={SampleRateValueStrings}
                        value={LocalDevice.deviceDataStore.advancedSettings.sampleRateIndex.toString()}
                        handleChange={(e) => {
                            console.log(
                                `Selected sample rate index: ${e.target.value}`
                            )
                            LocalDevice.deviceDataStore.advancedSettings.sampleRateIndex =
                                e.target.value.toString()
                        }}
                    />
                    <label className={`pt-1 text-sm`}>Network buffer</label>
                    <GenericSelector
                        title="Network buffer"
                        options={NetworkBufferSizeStrings}
                        value={
                            LocalDevice.deviceDataStore.advancedSettings
                                .networkBufferSizeIndex
                        }
                        handleChange={(e) => {
                            console.log(
                                `Selected network buffer index: ${e.target.value}`
                            )
                            LocalDevice.deviceDataStore.advancedSettings.networkBufferSizeIndex =
                                e.target.value.toString()
                        }}
                    />
                    <label className={`pt-1 text-sm`}>Network Interface</label>
                    <GenericSelector
                        title="Network Interface"
                        options={LocalDevice.deviceDataStore.getaNICs()}
                        value={LocalDevice.deviceDataStore.advancedSettings.nicIndex?.toString()}
                        handleChange={(e) => {
                            if (
                                LocalDevice.deviceDataStore.advancedSettings
                                    .nicIndex != e.target.value
                            ) {
                                LocalDevice.deviceDataStore.advancedSettings.nicIndex =
                                    e.target.value.toString()
                                LocalDevice.logger.warn(
                                    `New Selected NIC index: ${e.target.value} - must reconnect engine`
                                )
                            } else {
                                fNOP()
                            }
                        }}
                    />
                    <label className={`pt-1 text-sm`}>Manual Jitter</label>
                    <GenericSelector
                        title="Manual Jitter"
                        options={ManualJitterValueStrings}
                        value={LocalDevice.deviceDataStore.advancedSettings.manualJitterIndex.toString()}
                        handleChange={(e) => {
                            const jitterIndex = e.target.value
                            LocalDevice.deviceDataStore.advancedSettings.manualJitterIndex =
                                jitterIndex.toString()
                            const ra = LocalRemoteManager.workingData.remotes
                            if (ra.length) {
                                let jitterString: string =
                                    LocalDevice.deviceDataStore.advancedSettings
                                        .manualJitterIndex
                                if (jitterString === "0") {
                                    jitterString = ManualJitterValueStrings[0]
                                }
                                ra.map((remote) => {
                                    console.log(
                                        `Selected jitter index for ${remote.remoteName} : ${jitterString}`
                                    )
                                    if (jitterIndex != 0) {
                                        Agent.send(
                                            new setReceiverBufferSizeMessage(
                                                remote.remoteUserID,
                                                jitterIndex.toString()
                                            )
                                        )
                                    }
                                })
                            } else {
                                fNOP()
                            }
                        }}
                    />
                    <label className={`pt-1 text-sm`}>Codec</label>
                    {/* this uses an object not an array as an example of how to do it in a slightly different way */}
                    <GenericSelector
                        options={CodecOptionStrings}
                        value={LocalDevice.deviceDataStore.advancedSettings.codecOptionsIndex.toString()}
                        handleChange={(e) => {
                            console.log(`Selected codec: ${e.target.value}`)
                            LocalDevice.deviceDataStore.advancedSettings.codecOptionsIndex =
                                Number(e.target.value).toString()
                            Agent.send(
                                new setStreamQualityMessage(
                                    LocalDevice.deviceDataStore.advancedSettings.codecOptionsIndex
                                )
                            )
                        }}
                    />
                </div>
                <hr className="my-8 border-bonza-grey" />
                {navigator.userAgent.toLowerCase().indexOf("windows") > -1 ? (
                    <>
                        <hr className="my-8 border-bonza-grey" />
                        <PrimaryButton
                            disabled={false}
                            onClick={asioClickHandler}
                        >
                            ASIO
                        </PrimaryButton>
                    </>
                ) : null}
            </>
        )
    }
}
