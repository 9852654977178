// This is a functional service containing getter functions for axios
import axios, { AxiosResponse } from "axios"
import LoggerService from "@/services/LoggerService"
import {
    BonzaSessionResponse as SessionResponse,
    BonzaSessionRequest as SessionRequest,
    BonzaSessionInviteRequest as SessionInviteRequest,
    BonzaSessionResponse,
} from "@/types/BonzaSession"
import { UserModel } from "@/types/UserClass"
import {
    deleteRequest,
    getRequest,
    postRequest,
    putRequest,
} from "./helpers/AxiosHelper"
import { BonzaSessionInviteResponse as SessionInviteResponse } from "@/types/Invite"
import { ConnectionWithUserResource } from "@/types/Connection"

export const getSessions = async () => {
    const debRouteName = "api.session.index"
    const debRoute = route(debRouteName)

    const res = await getRequest<SessionResponse[]>(debRoute, "Sessions")

    return res
}

export const getConnections = async (session: string) => {
    return await getRequest<ConnectionWithUserResource[]>(
        route("api.session.connections", { session }),
        "Session"
    )
}

export const getSession = async (session: string) => {
    const debRouteName = "api.session.get"
    const debRoute = route(debRouteName, { session })

    const res = await getRequest<SessionResponse>(debRoute, "Sessions")

    return res
}

export const postSession = async (data: SessionRequest) => {
    const debRouteName = "api.session.create"
    const debRoute = route(debRouteName, { addCreator: true })

    const res = await postRequest<SessionRequest, SessionResponse>(
        debRoute,
        data,
        "Sessions"
    )

    return res
}

export const putSession = async (id: string, data: SessionRequest) => {
    const debRouteName = "api.session.update"
    const debRoute = route(debRouteName, { session: id })

    const res = await putRequest<SessionRequest, SessionResponse>(
        debRoute,
        data,
        "Sessions"
    )

    return res
}

export const deleteSession = async (id: string) => {
    const debRouteName = "api.session.delete"
    const debRoute = route(debRouteName, { session: id })

    const res = await deleteRequest(debRoute)

    return res
}

export const getSessionMembers = async (id: string) => {
    const logger = new LoggerService("Sessions")
    const debRouteName = "api.session.members"
    const debRoute = route(debRouteName, { id })

    const res = await axios
        .get(debRoute)
        .then((response: AxiosResponse<UserModel[]>) => {
            console.log(response.data)
            return response.data
        })
        .catch((error) => {
            logger.warn(`${debRouteName} call failed: ${error}`)
            return undefined
        })

    if (res === undefined) throw Error()

    return res
}

export const postInviteToSession = async (
    sessionId: string,
    userId: string,
    data: SessionInviteRequest
) => {
    const debRouteName = "api.session.invite"
    const debRoute = route(debRouteName, { session: sessionId, user: userId })

    const res = await postRequest<SessionInviteRequest, SessionInviteResponse>(
        debRoute,
        data,
        "Sessions"
    )

    return res
}

export const postSessionAcceptInvite = async (id: number) => {
    const debRouteName = "api.session.accept"
    const debRoute = route(debRouteName, { id })

    const res = await postRequest<never, BonzaSessionResponse>(
        debRoute,
        undefined,
        "Sessions"
    )

    return res
}

export const postSessionRevokeInvite = async (id: number) => {
    const debRouteName = "api.session.revoke"
    const debRoute = route(debRouteName, { id })

    const res = await postRequest<never, SessionInviteResponse>(
        debRoute,
        undefined,
        "Sessions"
    )

    return res
}
