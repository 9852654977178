import {
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    MenuSection,
} from "@headlessui/react"
import { InviteResponse } from "@/types/Invite"
import { useMemo } from "react"
import Invite from "./Invite"
import { useDateFormatter } from "@/services/helpers/Hooks"

type HeaderInvitesProps = {
    icon: JSX.Element
    invites: InviteResponse
    groupCallback: (id: number, accept: boolean) => void
    sessionCallback: (id: number, accept: boolean) => void
}

export default function HeaderInvites({
    icon,
    invites: { group, bonza_session },
    groupCallback,
    sessionCallback,
}: HeaderInvitesProps) {
    const numInvites = useMemo(() => {
        return (group.length ?? 0) + (bonza_session.length ?? 0)
    }, [group, bonza_session])

    const sessionInvites = useMemo(
        () =>
            bonza_session.map((session) => ({
                ...session,
                startTime: {
                    ...useDateFormatter(session.bonza_session.starts_at),
                },
                endTime: { ...useDateFormatter(session.bonza_session.ends_at) },
            })),
        [bonza_session.length]
    )

    return (
        <Menu>
            <MenuButton className="group">
                <div
                    className={`
                        relative rounded-full bg-bonza-dark-semi p-3
                        text-bonza-pale-t-70 ring-0 transition duration-100
                        ease-in-out

                        group-hover:ring group-hover:ring-bonza-primary
                    `}
                >
                    {icon}
                    {numInvites > 0 && (
                        <span
                            className={`
                                absolute -right-0.5 -top-0.5 h-4 w-4
                                rounded-full bg-bonza-primary text-xs
                                text-bonza-pale
                            `}
                        >
                            {numInvites}
                        </span>
                    )}
                </div>
            </MenuButton>
            <MenuItems
                anchor="bottom end"
                transition
                className={`
                    group mt-1 flex min-w-52 origin-top-right flex-col gap-5
                    rounded-3xl bg-bonza-dark-semi p-4 text-sm/6 text-bonza-pale
                    shadow-2xl transition duration-200 ease-in-out

                    data-[closed]:-translate-y-1 data-[closed]:opacity-0

                    focus:outline-none
                `}
            >
                {group.length === 0 && sessionInvites.length == 0 && (
                    <MenuSection>
                        <MenuItem>
                            <span
                                className={`
                                    flex w-full items-center rounded-lg px-3
                                    py-1.5
                                `}
                            >
                                No pending invites
                            </span>
                        </MenuItem>
                    </MenuSection>
                )}
                {group && group.length !== 0 && (
                    <h2 className="pl-1 text-lg text-bonza-pale">{"Groups"}</h2>
                )}
                {group &&
                    group.length !== 0 &&
                    group.map((invite) => (
                        <Invite
                            key={invite.id}
                            id={invite.id}
                            invitedBy={invite.invited_by_user}
                            message={
                                invite.message ??
                                `You've been invited to ${invite.group.name}`
                            }
                            name={invite.group.name}
                            callback={groupCallback}
                        />
                    ))}
                {sessionInvites && sessionInvites.length !== 0 && (
                    <h2 className="pl-1 text-lg text-bonza-pale">
                        {"Sessions"}
                    </h2>
                )}
                {sessionInvites &&
                    sessionInvites.length !== 0 &&
                    sessionInvites.map((invite) => {
                        return (
                            <Invite
                                key={invite.id}
                                id={invite.id}
                                invitedBy={invite.invited_by_user}
                                message={
                                    invite.message ??
                                    `You've been invited to ${invite.bonza_session.name}`
                                }
                                name={invite.bonza_session?.name}
                                time={`${invite.startTime.dayMonth} ${invite.startTime.monthShort}, ${invite.startTime.time} - ${invite.endTime.time}`}
                                callback={sessionCallback}
                            />
                        )
                    })}
            </MenuItems>
        </Menu>
    )
}
